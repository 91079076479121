import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Subnavigation = ({children}) => {
	return <div className="gst-subnavigation">{children}</div>;
};

Subnavigation.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Subnavigation;

import React from 'react';
import {Breadcrumb} from 'gatsby-plugin-breadcrumb';

import './style.scss';

const CustomBreadcrumb = ({crumbs, separator}) => {
	const crumbSeparator = separator || '>';

	return crumbs ? (
		<div className="ptr-breadcrumb">
			<Breadcrumb crumbs={crumbs} crumbSeparator={crumbSeparator} />
			<span className="breadcrumb__separator">{crumbSeparator}</span>
		</div>
	) : null;
};

export default CustomBreadcrumb;

import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Anchors = ({children}) => {
	return <div className="gst-anchors">{children}</div>;
};

Anchors.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Anchors;

import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Icon} from '@gisatcz/ptr-atoms';

import {getTranslated, lang} from '../../helpers/lang';
import {getPersonByID} from '../../selectors/data';

import './style.scss';

const Image = props => (
	<StaticQuery
		query={graphql`
			{
				images: allFile(
					filter: {
						extension: {regex: "/(jpg)|(jpeg)|(png)/"}
						relativeDirectory: {eq: "pages/company/persons"}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								gatsbyImageData(width: 600, layout: CONSTRAINED)
							}
						}
					}
				}
			}
		`}
		render={data => {
			const image = data.images.edges.find(n => {
				return n.node.relativePath.includes(props.filename);
			});
			if (!image) {
				const placeholderImage = data.images.edges.find(n => {
					return n.node.relativePath.includes('placeholder');
				});

				return (
					<GatsbyImage
						alt={props.alt}
						image={placeholderImage.node.childImageSharp.gatsbyImageData}
						className={'gst-person-badge-image'}
					/>
				);
			}
			return (
				<GatsbyImage
					alt={props.alt}
					image={image.node.childImageSharp.gatsbyImageData}
					className={'gst-person-badge-image'}
				/>
			);
		}}
	/>
);

const PersonBadge = ({email, note, cta}) => {
	const person = getPersonByID(email);
	cta = cta || 'Contact our expert,';
	let link = person.showEmail ? 'mailto:' + person.email : '#';
	return (
		<div className={'gst-responsible-person'}>
			<div className="gst-person-badge">
				<div className="gst-person-badge-header">
					<div>{getTranslated(note, lang)}</div>
					<div>
						<span>{getTranslated(cta, lang)}&nbsp;</span>
						<a className={'gst-person-name'} href={link}>
							<span>{person.name}</span>
							<Icon icon="mail" />
						</a>
					</div>
				</div>
				<Image filename={email} />
			</div>
		</div>
	);
};

PersonBadge.propTypes = {
	email: PropTypes.string.isRequired,
	note: PropTypes.string,
	cta: PropTypes.string,
};

export default PersonBadge;

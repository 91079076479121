import React from 'react';
import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Block from '../../../../components/Block';
import {graphql, useStaticQuery} from 'gatsby';
import Hero from '../../../../components/Hero';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import {getTranslated, lang} from '../../../../helpers/lang';
import Breadcrumb from '../../../../components/Breadcrumb';
import Subnavigation from '../../../../components/Subnavigation';
import Anchors from '../../../../components/Anchors';
import PersonBadge from '../../../../components/PersonBadge';
import Content from '../../../../components/Content';

const SeniorDesigner22 = ({pageContext}) => {
	const position = pageContext.position;
	const data = useStaticQuery(graphql`
		{
			background: file(
				relativePath: {eq: "pages/company/openPositions/gisOperator.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Senior Designer (UX)" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>
					{getTranslated(position.title, lang)}
				</HighlightedTitle>
				<HighlightedParagraph>
					{getTranslated(position.description, lang)}
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#your-job'}>Your job</a>
							<a href={'#our-job'}>Our job</a>
							<a href={'#our-way-of-work'}>Our way of work</a>
							<a href={'#what-you-get'}>What do you get for it?</a>
							<a href={'#hiring-process'}>Hiring process</a>
						</Anchors>

						{position.open ? (
							<PersonBadge
								note={pageContext.position.contactNote}
								cta={pageContext.position.contactCta}
								email={pageContext.position.contactPerson}
							/>
						) : (
							<div className={'gst-responsible-person'}>
								<div className={'gst-position-closed'}>
									This position is no longer open.
								</div>
							</div>
						)}
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						We’re looking for an experienced designer to work on our web
						framework and the apps we build with it. If you’d like to work on a
						variety of projects utilising satellite data for a small but well
						established company, driven by results instead of hours, get in
						touch!
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'your-job'}>Your job</h3>
					<p>
						We design apps for work with geographical data, mainly satellite
						imaging. We develop our own component system for building them. We
						mostly work on the web, occasionally on a mobile app. Component
						design is largely done while designing the individual applications,
						it’s necessary to be able to manage both aspects.
					</p>
					<p>
						The core of our apps is usually a map interface and/or data
						visualisation. On the other hand, the subject and target audience
						differ - from tools tailored to specialised professionals to general
						popularisation.
					</p>
					<p>
						The design is closely connected to front-end development. You don’t
						have to be able to code (though you should of course understand the
						medium), but you won’t hear the word “handoff” with us. Research, on
						the other hand, we’ve recently separated, so the problems mix with
						solutions as little as possible.
					</p>
					<p>
						We’ll want you to pass on the best practices and tech skills to
						junior colleagues, and you’ll have room for your self-improvement,
						too - even in a different direction you’re interested in. For that,
						we have dedicated time. As people, we know the importance of people.
					</p>
					<p>
						The vast majority of materials are in English, as most clients are
						international, and so is our internal documentation. Some of our
						colleagues are also foreign, so a good grasp of English is useful.
						On the other hand, you don’t need to know Czech at all.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'our-job'}>Our job</h3>
					<p>
						At Gisat, we transform satellite data into useful information,
						helping our clients make better decisions. Most of our projects have
						real-world impact - sometimes small, other times bigger. Although
						technically similar, the projects are also diverse, so you don’t get
						bored. In a year, we may work on checking the use of subsidies in
						agriculture or on infrastructure movement monitoring for the Czech
						state, water monitoring for the EU, examination of urban expansion
						for the World Bank or cooperation on a 3D map framework with NASA.
					</p>
					<p>
						Our team develops applications for presentation and visualisation of
						satellite and derived data. Experience with work with large datasets
						is welcome, domain knowledge is not needed.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'our-way-of-work'}>Our way of work</h3>
					<p>
						Your results are important to us, not when you’re sitting on a
						chair. Naturally, you need to cooperate with the rest of the team
						and sometimes you have to take part in meetings, but we don’t have
						any other limitations on working hours. We do prefer someone we can
						meet in the office on most days (pandemics excluding), but we can
						agree on full remote work as well. Whether you want to work full
						time or part time, as an employee or a contractor is also up for
						negotiation. We don’t care what OS you’ll want on your work machine,
						and to take a vacation for a month is usually no problem.
					</p>
					<p>
						The whole company is less than thirty people, so you don’t need to
						be afraid of complicated processes and structures or communicating
						through an HR department. We work in pleasant offices, several
						people large. We’re based in Letná, so most of Prague is close by,
						and we have a garden good for grill parties, a relaxation room or a
						place to park your bike.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'what-you-get'}>What do you get for it?</h3>
					<p>
						Pay CZK 70 000 - 120 000 + bonuses, 5 weeks vacation time, benefits:
						contributions toward meals, pension and public transport
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'hiring-process'}>Hiring process</h3>
					<p>
						If you’re interested in the offer and reply, within a few days we’ll
						invite you for a personal interview with someone from your potential
						team - we’ll agree if online or onsite. If we like each other,
						you’ll have the option (either right away, or later) to meet and
						talk with other members of the team and with the director. At this
						stage at the latest, we’ll want to see examples of your work.
					</p>
					<p>
						In any case, we will let you know whether or not we want you for our
						team as soon as we know.
					</p>
					<p>
						If we agree on cooperation, you can start as needed, both
						immediately or after eventual release from your previous job.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

export default SeniorDesigner22;
